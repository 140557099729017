import React, { Component } from "react"
import { Link } from "gatsby"
import css from "./LinkComponent.module.scss"

class LinkComponent extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <>
        {this.props.link && (
          <div className={[css.container, this.props.isTitle ? '' : css.notTitleAnchor].join(' ')}>
            {this.props.link.internalLink ? (
              <Link data-action-detail={this.props.link.name} to={this.props.link.internalLink}
              >{this.props.children}</Link>
            ) : (
              <a data-action-detail={this.props.link.name}
                title={this.props.link.name} target="_blank" href={this.props.link.externalLink}>
                {this.props.children}
              </a>
            )}
          </div>
        )}
      </>
    )
  }
}
export default LinkComponent
