import React, { Component } from "react"
import css from "./HeaderNav.module.scss"
import LinkComponent from "../../components/LinkComponent/LinkComponent"
import HamburgerIcon from "../../images/svgs/hamburgerMenu.svg"

class HeaderNav extends Component {
  constructor(props) {
    super(props)

    this.state = {
      leftNavList: [],
      rightNavList: [],
      isMobileDropdownOpen: false,
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.data && nextProps.data.contentfulNavigationHeader && nextProps.data.contentfulNavigationHeader.headerList) {
      const headerList = nextProps.data.contentfulNavigationHeader.headerList
      let leftNavList = []
      let rightNavList = []
      for (let i = 0; i < headerList.length; i++) {
        if (headerList[i].placement && headerList[i].placement.toLowerCase() === "left") {
          leftNavList.push(headerList[i])
        } else {
          rightNavList.push(headerList[i])
        }
      }

      return { leftNavList, rightNavList }
    } else return null
  }
  changeMobileDropdownOpen() {
    this.setState(prevState => {
      return { isMobileDropdownOpen: !prevState.isMobileDropdownOpen }
    })
  }
  render() {
    return (
      <div className={[css.container, this.props.backgroundWhiteColor ? css.containerWhite : css.containerWhite].join(' ')}>
        <div className={[css.desktopWrapper, css.mainWrapper].join(" ")}>
          <div className={css.headerTitleWrapper}>
            <LinkComponent {...{ isTitle: true ,link: { internalLink: "/", name: "Story Et Fall" } }}>
              <div className={css.headerTitle}>STORY ET FALL</div>
            </LinkComponent>
          </div>
          {this.state.leftNavList.map((leftNavListItem, leftNavListItemIndex) => {
            return (
              <div key={leftNavListItemIndex}>
                <LinkComponent {...leftNavListItem}>{leftNavListItem.name}</LinkComponent>
              </div>
            )
          })}
          {this.state.rightNavList.map((rightNavListItem, rightNavListItemIndex) => {
            return (
              <div key={rightNavListItemIndex}>
                <LinkComponent {...rightNavListItem}>{rightNavListItem.name}</LinkComponent>
              </div>
            )
          })}
        </div>
        {/* <div className={[css.mobileWrapper, css.mainWrapper].join(" ")}>
          <div className={css.haburgerMenu} onClick={() => this.changeMobileDropdownOpen()}>
            <img src={HamburgerIcon}></img>
          </div>
          {this.state.isMobileDropdownOpen == true && (
            <>
              {this.state.leftNavList.map((leftNavListItem, leftNavListItemIndex) => {
                return (
                  <div key={leftNavListItemIndex}>
                    <LinkComponent {...leftNavListItem}>{leftNavListItem.name}</LinkComponent>
                  </div>
                )
              })}
              {this.state.rightNavList.map((rightNavListItem, rightNavListItemIndex) => {
                return (
                  <div key={rightNavListItemIndex}>
                    <LinkComponent {...rightNavListItem}>{rightNavListItem.name}</LinkComponent>
                  </div>
                )
              })}
              <div className={css.headerTitleWrapper}>
                <LinkComponent {...{ link: { interalLink: "./", name: "Story Et Fall" } }}>
                  <div className={css.headerTitle}>STORY ET FALL</div>
                </LinkComponent>
              </div>
            </>
          )}
        </div> */}
      </div>
    )
  }
}

export default HeaderNav
